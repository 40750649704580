import { useState } from "react";import BackgroundButton from "../Elements/BackgroundButton";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";
import ProfileModal from "../Profile/ProfileModal";
import { House, BookCopy, BadgePlus, CirclePlay, NotebookText, Shuffle, Zap } from 'lucide-react';

function TitleBar( { text, content, home = false }) {

  // images

  const navigate = useNavigate();
  const { profile, logout, theme } = useUser()
  const { primaryColor } = theme;
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const Cards = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
      <path d="M9.4 7.53333C9.2 7.26667 8.8 7.26667 8.6 7.53333L6.225 10.7C6.09167 10.8778 6.09167 11.1222 6.225 11.3L8.6 14.4667C8.8 14.7333 9.2 14.7333 9.4 14.4667L11.775 11.3C11.9083 11.1222 11.9083 10.8778 11.775 10.7L9.4 7.53333Z"/>
      <path d="M4.09245 5.63868C4.03647 5.5547 4.03647 5.4453 4.09245 5.36133L4.79199 4.31202C4.89094 4.16359 5.10906 4.16359 5.20801 4.31202L5.90755 5.36132C5.96353 5.4453 5.96353 5.5547 5.90755 5.63867L5.20801 6.68798C5.10906 6.83641 4.89094 6.83641 4.79199 6.68798L4.09245 5.63868Z"/>
      <path d="M13.208 15.312C13.1091 15.1636 12.8909 15.1636 12.792 15.312L12.0924 16.3613C12.0365 16.4453 12.0365 16.5547 12.0924 16.6387L12.792 17.688C12.8909 17.8364 13.1091 17.8364 13.208 17.688L13.9075 16.6387C13.9635 16.5547 13.9635 16.4453 13.9075 16.3613L13.208 15.312Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1 4C1 2.34315 2.34315 1 4 1H14C15.1323 1 16.1181 1.62732 16.6288 2.55337L20.839 3.68148C22.4394 4.11031 23.3891 5.75532 22.9603 7.35572L19.3368 20.8787C18.908 22.4791 17.263 23.4288 15.6626 23L8.19849 21H4C2.34315 21 1 19.6569 1 18V4ZM17 18V4.72339L20.3213 5.61334C20.8548 5.75628 21.1714 6.30461 21.0284 6.83808L17.405 20.361C17.262 20.8945 16.7137 21.2111 16.1802 21.0681L15.1198 20.784C16.222 20.3403 17 19.261 17 18ZM4 3C3.44772 3 3 3.44772 3 4V18C3 18.5523 3.44772 19 4 19H14C14.5523 19 15 18.5523 15 18V4C15 3.44772 14.5523 3 14 3H4Z"/>
    </svg>
  )

  const logoutUser = () => {
    logout()
    navigate('/');
  } 

  const [isOpen, setIsOpen] = useState(false);

  const images = {
    Dashboard: <BookCopy />,
    Create: <BadgePlus />,
    Practice: <CirclePlay />,
    Home: <House />,
    Quiz: <NotebookText />,
    Scramble: <Shuffle />,
    Memory: Cards,
    Dash: <Zap />,
  };
  
  var firstImg = images[text] || null;

  return (
    <div className="flex justify-between px-4 my-2">
      {/* Drop Down Navigation */}
      <div className="relative inline-block text-left z-50 ">
        {/* Use BackgroundButton as the main button */}
        <div className="flex gap-2">
          {!home && (
          <BackgroundButton
            bgColor={theme ? `${primaryColor.bgClass} ${primaryColor.hoverClass}` : 'bg-green-500 hover:bg-green-400'}
            wWidth="w-44"
            image={<House />}
            flip
            onClick={() => navigate(`/home`)}
          />
          )}
        
          <BackgroundButton
            text={text}
            bgColor={theme ? `${primaryColor.bgClass} ${primaryColor.hoverClass}` : 'bg-green-500 hover:bg-green-400'}
            wWidth="w-44"
            image={firstImg}
            flip
            onClick={() => setIsOpen(!isOpen)}
          />

          {/* Dropdown options with animation */}
          <div
            className={`p-1 absolute text-white text-xl font-bold left-0 ${!home ? 'ml-12' : ''} mt-12 w-44 ${primaryColor.bgClass} background-shadow-new rounded-3xl transform transition-all duration-300 origin-top ${
              isOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0'
            }`}
            style={{ transformOrigin: 'top' }} // Ensure the dropdown opens from the top
          >
            <LinkButton text="Home" img={<House />} hoverClass={primaryColor.hoverClass}/>
            <LinkButton text="Dashboard" img={<BookCopy />} hoverClass={primaryColor.hoverClass}/>
            <LinkButton text="Create" img={<BadgePlus />} hoverClass={primaryColor.hoverClass}/>
            <LinkButton text="Practice" img={<CirclePlay />} hoverClass={primaryColor.hoverClass}/>
            <LinkButton text="Memory" img={Cards} hoverClass={primaryColor.hoverClass}/>
            <LinkButton text="Quiz" img={<NotebookText />} hoverClass={primaryColor.hoverClass}/>
            <LinkButton text="Scramble" img={<Shuffle />} hoverClass={primaryColor.hoverClass}/>
            <LinkButton text="Dash" img={<Zap />} hoverClass={primaryColor.hoverClass}/>
          </div>
        </div>
        
      </div>

      {/* User Profile and Additional Content */}
      <div className="flex gap-2">
        {content}
        <div className={`w-10 h-10 rounded-full bg-black flex items-center justify-center background-shadow-new background-hover cursor-pointer`}
          onClick={() => setIsProfileOpen(true)}>
          <p className="text-white font-bold text-xl">{profile?.first_name?.charAt(0) || 'U'}</p>
        </div>
      </div>

      <ProfileModal 
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
        logout={logoutUser}
        profile={profile}
      />
    </div>
  )
}

export default TitleBar;

function LinkButton({ text, img, hoverClass }) {
  const navigate = useNavigate();
  const textLower = text.toLowerCase();

  const handleClick = () => {
    // Pass null subject to force refresh
    navigate(`/${textLower}`, { state: { subject: null } });
  };

  return (
    <div onClick={handleClick} className={`flex items-center justify-start ${hoverClass} p-1 w-full rounded-3xl cursor-pointer`}>
      {img}
      <span className="ml-2">{text}</span>
    </div>
  );
}